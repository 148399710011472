<script setup lang="ts">
import type { PropType } from "vue";
import type { Duration } from "dayjs/plugin/duration";
import { useDayjs } from "#dayjs";
import Calculator from "~/components/banner/calculator.vue";
import type { IBanner, IBannerAction } from "~/composable/banner.type";

const dayjs = useDayjs();

const props = defineProps({
  items: {
    type: Object as PropType<IBanner>,
    required: true,
  },
  title: {
    type: String,
    required: true,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});

const activeTo = ref(dayjs.tz(props.items?.action.active_to));
const activeFrom = ref(dayjs.tz(props.items?.action.active_from));
const now = ref(dayjs.tz());
const timeInterval = ref<NodeJS.Timeout | number | null>(null);

const diffDuration = computed<Duration>(() => {
  return dayjs.duration(activeTo.value.diff(now.value));
});

const actionTimeLeft = computed(() => {
  if (diffDuration.value.months() > 0)
    return `${diffDuration.value.months()} мес. ${diffDuration.value.days()} дн.`;
  if (diffDuration.value.days() > 0)
    return `${diffDuration.value.days()} дн. ${diffDuration.value.hours()} ч.`;
  if (diffDuration.value.hours() > 0)
    return `${diffDuration.value.hours()} ч. ${diffDuration.value.minutes()} м.`;
  if (diffDuration.value.minutes() > 0)
    return `${diffDuration.value.minutes()} м. ${diffDuration.value.seconds()} с.`;
  if (diffDuration.value.seconds() > 0)
    return `${diffDuration.value.seconds()} с.`;

  return "Акция завершена";
});

const actionTimePercent = (action: IBannerAction) => {
  const timeFull = dayjs.duration(activeTo.value.diff(activeFrom.value)).asHours(),
    timeLeft = diffDuration.value.asHours();
  return timeLeft < 0
    ? 100
    : Math.round((timeFull - timeLeft) / (timeFull / 100));
};
onMounted(() => {
  timeInterval.value = setInterval(() => {
    now.value = dayjs();
  }, 1000);
});
onBeforeUnmount(() => {
  if (timeInterval.value) {
    clearInterval(timeInterval.value);
  }
});
</script>

<template>
  <div class="form" v-if="items">
    <div class="container mx-auto">
      <div class="form__inner">
        <div class="form__inner-block">
          <div v-if="items.action" class="form__inner-action">
            <div class="form__inner-action__badge">Акция</div>
            <div
              class="form__inner-action__name"
              v-text="items.action.title"
            ></div>
          </div>
          <h1 class="form__inner-title" v-html="items.title"></h1>
          <div
            v-if="items.action"
            class="form__inner-info"
            v-text="items.action.description"
          ></div>
          <div v-if="items.action" class="form__time">
            <div class="flex justify-between">
              <div class="form__time-title">Осталось до конца акции</div>
              <client-only>
                <div class="form__time-left" v-text="actionTimeLeft"></div>
              </client-only>
            </div>
            <div class="flex flex-col gap-8px">
              <div class="form__time-line">
                <div
                  class="form__time-fill"
                  :style="'width: ' + actionTimePercent(items.action) + '%'"
                ></div>
              </div>
              <div
                v-if="items.action"
                class="flex justify-between items-center"
              >
                <div
                  class="form__time-date"
                  v-text="activeFrom.locale('ru').format('D MMM')"
                ></div>
                <div
                  class="form__time-date"
                  v-text="activeTo.locale('ru').format('D MMM')"
                ></div>
              </div>
            </div>
          </div>
          <div v-if="items.image" class="form__banner">
            <img :src="items.image" :alt="items.title" loading="lazy" />
          </div>
        </div>
        <div v-if="items.calculator" class="form__inner-block">
          <Calculator :calculator="items.calculator" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.form {
  @apply bg-secondary-block-blue-light pt-[48px]
  xl:pb-[48px] max-xl:-mt-[40px];

  &__inner {
    @apply flex max-xl:flex-col-reverse justify-between relative gap-[32px];

    &-block {
      @apply flex flex-col gap-16px
      md:gap-[32px];
    }

    &-action {
      @apply flex items-center gap-[12px]

      md:max-xl:self-center max-xl:justify-center;

      &__badge {
        @apply bg-secondary-block-blue text-secondary-blue-green rounded-[24px] py-4px px-24px text-normal-XXS-semi
        md:text-normal-S-bold md:py-8px;
      }

      &__name {
        @apply text-secondary-blue text-normal-XXS
        md:text-normal-S;
      }
    }

    &-title {
      @apply text-heading-XS max-xl:text-center
      md:text-heading-XL;
    }

    &-info {
      @apply text-normal-XS-compact-semi max-md:text-center
      md:max-xl:self-center
      md:text-normal-L-semi;
    }
  }

  &__time {
    @apply w-full flex flex-col gap-[12px]
    md:max-xl:self-center
    md:w-[405px];

    &-title {
      @apply text-normal-XS
      md:text-normal-M;
    }

    &-left {
      @apply text-normal-XS
      md:text-normal-M-bold;
    }

    &-line {
      @apply bg-secondary-gray-50 rounded-[4px] h-[8px] relative;
    }

    &-fill {
      @apply absolute left-0 bg-secondary-blue rounded-[4px] h-[8px];
    }

    &-date {
      @apply text-normal-XXXS
      md:text-normal-XS;
    }
  }

  &__banner {
    @apply max-xl:flex justify-center self-center
    xl:self-start
    xl:left-0 xl:relative xl:-bottom-[48px];
  }
}
</style>
