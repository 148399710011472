<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { useDayjs } from "#dayjs";
import type { PropType } from "vue";
import noUiSlider, { type API, type Options } from "nouislider";
import { TransitionFade, TransitionExpand } from "@morev/vue-transitions";
import type { ICalculator } from "~/composable/banner/calculator.type";
import EqZaimIcon from "~/components/icons/eqZaimIcon.vue";
import { randomString } from "~/composable/randomString";
import { useMetricsStore } from "~/stores/metrics.store";
import type { TEventsTabs } from "~/stores/metrics.store.type";
import type { ITidButton, ITidParams } from "~/composable/tidSDK";

const config = useRuntimeConfig();
const requestUrl = useRequestURL();
const metricsStore = useMetricsStore();

const dayjs = useDayjs();
const props = defineProps({
  calculator: {
    type: Object as PropType<ICalculator>,
    required: true,
  },
});

const popup = ref(null);
const tinkoffContainer = ref<HTMLDivElement>();
const cpgContainer = ref<HTMLDivElement>();
const lidForm = ref<HTMLFormElement>();

const date = new Date();
date.setDate(date.getDate() + 365);
const dateNow = Math.floor(Date.now() / 1000);
const deviceCookie = useCookie("BITRIX_SM_GUEST_DEVICE_ID", {
  default() {
    return randomString(10) + dateNow + randomString(10);
  },
  httpOnly: true,
  expires: date,
});

if (deviceCookie.value.length != 30) {
  deviceCookie.value = randomString(10) + dateNow + randomString(10);
}

const data = reactive({
  currentTab: 0,
  tinkoff: {
    enabled: false,
    url: "",
  },
  esia: {
    enabled: false,
    url: "",
  },
  form: {
    sum: props.calculator.tabs[0].sum.default,
    time: props.calculator.tabs[0].time.default,
  },
});

const curTab = computed(() => {
  return props.calculator.tabs[data.currentTab];
});

const isFirstLoan = computed(() => {
  return curTab.value.sum.to <= 15000;
});

const timeFormated = computed(() => {
  const dayWords = ["день", "дня", "дней"];
  const weekWords = ["неделя", "недели", "недель"];
  const time = timeValue.value;

  let value: number;
  let words: string[];

  if (time > maxDays.value) {
    value = Math.round(time / 7);
    words = weekWords;
  } else {
    value = time;
    words = dayWords;
  }

  const num = Math.abs(value) % 100;
  const num_x = Math.abs(num) % 10;

  let word: string;
  if (num > 10 && num < 20) {
    word = words[2];
  } else if (num_x > 1 && num_x < 5) {
    word = words[1];
  } else if (num_x == 1) {
    word = words[0];
  } else {
    word = words[2];
  }

  return `${value} ${word}`;
});

const sumFormated = computed(() => {
  const numberFormat = new Intl.NumberFormat("ru-RU", {
    style: "decimal",
    maximumFractionDigits: 0,
  });
  return `${numberFormat.format(data.form.sum)} ₽`;
});

const sumValue = computed<number>(() => data.form.sum);
const timeValue = computed<number>(() => data.form.time);
const isFree = computed<boolean>(
  () =>
    data.form.time < props.calculator?.tabs[data.currentTab].free.time &&
    data.form.sum < props.calculator?.tabs[data.currentTab].free.sum,
);
const isWeek = computed<boolean>(
  () => timeValue.value > maxDays.value && sumValue.value >= 30000,
);

const moneyTime = computed<string>(() =>
  dayjs().add(10, "minutes").format("HH:mm"),
);

const sumText = computed<string>(() =>
  isWeek.value ? "Платеж раз в 2 недели" : "Вы вернёте",
);

const calculateSum = computed<number>(() => {
  if (isWeek.value) {
    let daysForPayment = 14,
      countPayment = Math.ceil(data.form.time / daysForPayment),
      shareRate =
        (props.calculator?.tabs[data.currentTab].percent * daysForPayment) /
        100,
      annuityRatio =
        (shareRate * Math.pow(1 + shareRate, countPayment)) /
        (Math.pow(1 + shareRate, countPayment) - 1);
    return Math.round(sumValue.value * annuityRatio);
  }
  const fullPercent =
    data.form.time * props.calculator?.tabs[data.currentTab].percent;

  let value = (sumValue.value * (1 + fullPercent / 100)) / 0.1;
  value = Math.ceil(Math.round(value * 10) / 10) * 0.1;

  return Math.round(value);
});

const calculateSumOldFormatted = computed<string>(() =>
  calculateSum.value.toLocaleString("ru-RU"),
);
const calculateSumFormatted = computed<string>(
  () =>
    `${(isFree.value ? data.form.sum : calculateSum.value).toLocaleString("ru-RU")} ₽`,
);

const returnPopupVisible = ref(false);
onClickOutside(popup, (event) => (returnPopupVisible.value = false));
const esiaAuth = async () => {
  try {
    await fetch(config.public.esia, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((d) => {
        if (d.result && d.redirectUrl) {
          data.esia.enabled = true;
          data.esia.url = d.redirectUrl;
        }
      });
  } catch (error) {
    console.error(`Ошибка - ${error}`);
  }
};
const tinkoffAuth = async () => {
  try {
    await fetch(config.public.tinkoff, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((d) => {
        if (d.result && d.isEnabled) {
          const authParams = {
            redirectUri: d.redirectUri,
            responseType: d.responseType,
            clientId: d.clientId,
            state: d.state,
          } as ITidParams;

          const uiParams = {
            container: `#tinkoffButton`,
            size: "l",
            color: "business",
            text: `Tinkoff`,
            target: "_top",
          } as ITidButton;
          // tinkoffContainer.innerHTML = "";
          const tidSdk = new TidSDK(authParams);

          tidSdk.addButton(uiParams);
          data.tinkoff.enabled = true;
        }
      });
  } catch (error) {
    console.error(`Ошибка - ${error}`);
  }
};

const tidClick = async () => {
  try {
    await metricsStore.fetchMetrics("TINKOFF");
  } catch (e) {}
  const anchor = tinkoffContainer.value?.nextElementSibling
    ?.firstElementChild as HTMLButtonElement;
  anchor.click();
};
const cpgClick = async () => {
  try {
    await metricsStore.fetchMetrics("CPG");
  } catch (e) {}
  const anchor = cpgContainer.value?.nextElementSibling
    ?.firstElementChild as HTMLAnchorElement;
  anchor.click();
};

const submitForm = async () => {
  try {
    await metricsStore.fetchMetrics(
      data.currentTab === 0 ? "FormNew" : "FormOld",
    );
  } catch (e) {}
  lidForm.value?.submit();
};

const setCookies = () => {
  cookieAmount.value = data.form.sum;
  cookiePeriod.value =
    data.form.time > maxDays.value ? data.form.time / 7 : data.form.time;
  cookieProductType.value =
    data.form.time > maxDays.value ? "installment" : "pdl";
};

const timeSlider = ref<API>(),
  sumSlider = ref<API>(),
  timeRangeRef = ref(null),
  sumRangeRef = ref(null),
  updatingSliders = ref(false);

const curDateFormat = dayjs().format("DD.MM");

const minRestrict = computed(() => curTab.value.restriction.from),
  maxRestrict = computed(() => curTab.value.restriction.to),
  maxDays = computed(() => curTab.value.weeks);

const timeRange = computed(() => {
  if (!isFirstLoan.value) {
    return {
      min: curTab.value.time.from,
      "50%": [maxDays.value, 40],
      "66%": [70, 14],
      "82%": [84, 84],
      max: curTab.value.time.to,
    };
  } else {
    return {
      min: curTab.value.time.from,
      max: curTab.value.time.to,
    };
  }
});

const sumRange = computed(() => {
  return {
    min: curTab.value.sum.from,
    max: curTab.value.sum.to,
  };
});

const timeSettings = computed<Options>(() => {
  return {
    start: curTab.value.time.default,
    connect: "lower",
    range: timeRange.value,
    step: 1,
  } as Options;
});

const sumSettings = computed<Options>(() => {
  return {
    start: curTab.value.sum.default,
    connect: "lower",
    range: sumRange.value,
    step: 1000,
  } as Options; 
});

onMounted(() => {
  tinkoffAuth();
  esiaAuth();

  if (!timeRangeRef.value || !sumRangeRef.value) return;

  timeSlider.value = noUiSlider.create(timeRangeRef.value, timeSettings.value);
  sumSlider.value = noUiSlider.create(sumRangeRef.value, sumSettings.value);

  timeSlider.value?.on(
    "update",
    (values: (number | string)[], handleNumber: number) => {
      data.form.time =
        typeof values[handleNumber] === "string"
          ? parseInt(values[handleNumber])
          : values[handleNumber];

      if (!sumSlider.value || updatingSliders.value) return;

      if (
        data.form.sum > minRestrict.value &&
        data.form.sum <= maxRestrict.value
      ) {
        return;
      }
      if (
        data.form.sum === minRestrict.value &&
        data.form.time <= maxDays.value
      ) {
        sumSlider.value.set(minRestrict.value, false);
      } else if (
        data.form.time > maxDays.value &&
        data.form.sum <= maxRestrict.value
      ) {
        sumSlider.value.set(maxRestrict.value + 1, false);
      } else if (
        data.form.time <= maxDays.value &&
        data.form.sum >= maxRestrict.value
      ) {
        sumSlider.value.set(maxRestrict.value, false);
      }
    },
  );

  sumSlider.value?.on(
    "update",
    (values: (number | string)[], handleNumber: number) => {
      data.form.sum =
        typeof values[handleNumber] === "string"
          ? parseInt(values[handleNumber])
          : values[handleNumber];

      if (updatingSliders.value) return;
      if (!timeSlider.value) return;

      if (
        data.form.time > maxDays.value &&
        data.form.sum <= minRestrict.value
      ) {
        timeSlider.value.set(maxDays.value, false);
      } else if (data.form.time < 70 && data.form.sum > maxRestrict.value) {
        timeSlider.value.set(70, false);
      }
    },
  );
});

const changeTab = async (event: Event, index: number) => {
  data.currentTab = index;

  const tabEvent: TEventsTabs[] = ["TabNew", "TabOld"];

  try {
    await metricsStore.fetchMetrics(tabEvent[index]);
  } catch (e) {}

  const button = event.target as HTMLElement;

  if (!button || !sumSlider.value || !timeSlider.value) return;

  updatingSliders.value = true;
  timeSlider.value.updateOptions(timeSettings.value, true);
  sumSlider.value.updateOptions(sumSettings.value, true);
  updatingSliders.value = false;
};

const cookieDomain = computed(() => {
  let domain = "." + requestUrl.hostname;
  if (requestUrl.hostname.includes("mmk.local")) {
    domain = ".mmk.local";
  }
  return domain;
});

const expriresDate = new Date(date.setDate(date.getDate() + 1));
const cookieSettings = {
  domain: cookieDomain.value,
  expires: expriresDate,
};
const cookieAmount = useCookie<number>("amount", cookieSettings),
  cookiePeriod = useCookie<number>("term", cookieSettings),
  cookieProductType = useCookie<string>("product_type", cookieSettings);

const calcLink = computed<string>(() => {
  return isFirstLoan.value ? config.public.lkReg : config.public.lkAuth;
})
</script>

<template>
  <div class="calc">
    <div class="calc__top">
      <div class="calc__top-title" v-text="calculator.title"></div>
      <div class="calc__top-description">
        Высокое одобрение заявок — до 99% на
        <span v-text="curDateFormat"></span>
      </div>
    </div>
    <div
      class="grid bg-secondary-gray-50 p-4px rounded-full text-center gap-[2px] md:mx-[20px] md:max-xl:max-w-[469px] self-center w-full"
      :class="'grid-cols-' + calculator.tabs.length"
    >
      <template v-for="(tab, index) in calculator.tabs" :key="tab.code">
        <a
          href="#"
          :class="data.currentTab === index ? 'calc__tab_active' : ''"
          class="calc__tab"
          :data-testid="`tab-${index + 1}`"
          @click.prevent="changeTab($event, index)"
          v-text="tab.title"
        ></a>
      </template>
    </div>
    <div
      class="flex flex-col gap-[24px] md:max-xl:max-w-[469px] self-center w-full"
    >
      <div class="calc__slider">
        <div class="flex justify-between">
          <div class="calc__slider-title">Выберите срок</div>
          <div class="calc__slider-value" v-text="timeFormated"></div>
          <input
            type="hidden"
            :value="timeFormated"
            data-testId="loanPeriodText"
          />
        </div>
        <div class="calc__slider-wrapper">
          <div data-testId="loanPeriodSlider" ref="timeRangeRef"></div>
        </div>
      </div>
      <div class="calc__slider">
        <div class="flex justify-between">
          <div class="calc__slider-title">Выберите сумму</div>
          <div class="calc__slider-value" v-text="sumFormated"></div>
          <input
            type="hidden"
            :value="sumFormated"
            data-testId="loanAmountText"
          />
        </div>
        <div class="calc__slider-wrapper">
          <div data-testId="loanAmountSlider" ref="sumRangeRef"></div>
        </div>
        <div class="calc__slider-result">
          <div class="grid grid-cols-3">
            <div>
              <div class="flex flex-col gap-[4px] justify-between w-fit">
                <div class="calc__slider-result__title" v-text="sumText"></div>
                <div class="flex gap-[4px] items-center">
                  <div
                    v-if="isFree"
                    class="calc__slider-result__value_old"
                    v-text="calculateSumOldFormatted"
                  ></div>
                  <div
                    class="calc__slider-result__value"
                    v-text="calculateSumFormatted"
                  ></div>
                </div>
              </div>
            </div>
            <div class="flex justify-center">
              <div class="text-center flex flex-col gap-[4px] w-fit">
                <div class="calc__slider-result__title">Ставка</div>
                <div
                  v-if="isFree"
                  class="calc__slider-result__value text-secondary-blue"
                >
                  Бесплатно*
                </div>
                <div
                  v-else
                  class="calc__slider-result__value"
                  v-text="`${curTab.percent} %`"
                ></div>
              </div>
            </div>
            <div class="flex justify-end">
              <div class="text-center flex flex-col gap-[4px] w-fit">
                <div class="calc__slider-result__title">Деньги у вас</div>
                <div class="calc__slider-result__value">
                  в <span v-text="moneyTime"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calc__buttons">
      <TransitionExpand>
        <div
          class="flex gap-[12px]"
          v-show="data.tinkoff.enabled || data.esia.enabled"
        >
          <div
            v-show="data.tinkoff.enabled"
            class="calc__buttons-button calc__buttons-button_gray flex-grow"
          >
            <div
              class="calc__buttons-button-overlay"
              @click="tidClick()"
              ref="tinkoffContainer"
            ></div>
            <div id="tinkoffButton" data-testId="TinkoffButtonContainer"></div>
          </div>

          <div class="calc__buttons-button calc__buttons-button_gray flex-grow"
            v-show="data.esia.enabled">
            <div
              class="calc__buttons-button-overlay"
              @click="cpgClick()"
              ref="cpgContainer"
            ></div>
            <a
              :href="data.esia.url"
              data-testId="btnGetViaEsia"
            >
              <img
                src="/images/esia.svg"
                alt="Госуслуги"
                class="max-md:h-[18px]"
                loading="lazy"
              />
              Госуслуги
            </a>
          </div>
        </div>
      </TransitionExpand>
      <form
        method="get"
        v-on:submit="setCookies"
        ref="lidForm"
        :action="calcLink"
      >
        <input
          type="hidden"
          name="OfferSum"
          :value="sumValue"
          data-testId="loanFormSum"
        />
        <input
          type="hidden"
          name="OfferPeriod"
          :value="timeValue"
          data-testId="loanFormPeriod"
        />
        <client-only>
          <input type="hidden" name="DeviceID" :value="deviceCookie" />
        </client-only>
        <input type="hidden" name="City" value="" />
        <button
          class="calc__buttons-button calc__buttons-button_blue"
          data-testId="btnGetMoney"
          @click="submitForm()"
        >
          Получить деньги
        </button>
      </form>
    </div>
    <div v-if="calculator.info" class="flex justify-center">
      <div class="calc__return" ref="popup">
        <NuxtLink
          to="#"
          @click.prevent="returnPopupVisible = !returnPopupVisible"
          class="calc__return-toggle"
        >
          <EqZaimIcon
            icon-name="info-circle"
            class="text-[12px] md:text-[20px] text-secondary-gray"
          ></EqZaimIcon>
          <span v-text="calculator.info.title"></span>
        </NuxtLink>

        <div class="flex justify-center relative">
          <TransitionFade>
            <div v-if="returnPopupVisible" class="calc__return-modal">
              <span v-html="calculator.info.description"></span>
              <NuxtLink to="#" @click.prevent="returnPopupVisible = false">
                <EqZaimIcon
                  icon-name="close-square"
                  class="text-[16px] calc__return-modal__icon"
                ></EqZaimIcon>
              </NuxtLink>
            </div>
          </TransitionFade>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.calc {
  @apply bg-white flex flex-col gap-16px px-24px py-16px rounded-[24px]
  max-md:-mx-[24px]
  md:px-[105px] md:py-[48px] md:gap-[24px] md:rounded-form
  xl:p-[48px] xl:w-[606px];

  box-shadow:
    0 23px 50px 0 rgba(2, 102, 112, 0.05),
    0 90px 90px 0 rgba(2, 102, 112, 0.04),
    0 203px 122px 0 rgba(2, 102, 112, 0.03);

  &__top {
    @apply flex flex-col gap-8px;

    &-title {
      @apply text-center text-heading-XXXS
      md:text-heading-XS;
    }

    &-description {
      @apply text-center text-normal-XXS
      md:text-normal-M;
    }
  }

  &__tab {
    @apply py-[2px] rounded-full text-secondary-gray text-normal-XXS-bold transition-all
    md:text-normal-M-bold md:py-12px;

    &_active {
      @apply bg-white text-black;
    }
  }

  &__slider {
    @apply md:px-20px;

    &-title {
      @apply text-normal-S
      md:text-normal-L;
    }

    &-value {
      @apply text-normal-S-bold
      md:text-title-S;
    }

    &-wrapper {
      @apply h-[48px] flex flex-col justify-center
      md:h-[40px];
    }

    &-result {
      &__title {
        @apply text-normal-XXXS text-secondary-gray
        md:text-normal-XS;
      }

      &__value {
        @apply text-normal-XS-bold
        md:text-normal-M-semi;

        &_old {
          @apply text-normal-XS-compact text-secondary-gray line-through
          md:text-normal-S-regular;
        }
      }
    }
  }

  &__percent {
    @apply flex justify-center gap-[4px] text-normal-XXS
    md:text-normal-M md:gap-12px;

    :deep(span) {
      @apply text-secondary-blue;
    }
  }

  &__buttons {
    @apply flex flex-col gap-8px  md:max-xl:max-w-[510px] self-center w-full
    md:gap-16px;

    &-button {
      @apply flex items-center justify-center rounded-full text-center text-normal-S-bold cursor-pointer relative
      md:text-title-M;

      &-overlay {
        @apply absolute w-full h-full left-0 top-0;
      }

      a {
        @apply flex items-center gap-[12px];
      }

      &_blue {
        @apply bg-[linear-gradient(180deg,#0D95B2_0%,#026670_100%)] text-white py-24px uppercase w-full
        md:py-32px;
      }

      &_gray {
        @apply bg-secondary-gray-50 py-16px gap-6px
        md:py-24px md:gap-12px md:text-[25px] md:leading-[30px] ease-in-out duration-300;

        &:hover {
          @apply bg-secondary-blue-green text-white;
        }

        :deep(.tid-4PNRE-button) {
          @apply p-0 min-w-0 flex items-center gap-6px
          md:gap-12px;
        }

        :deep(.tid-4PNRE-text) {
          @apply text-normal-S-bold
          md:text-[25px] md:leading-[30px];
        }

        :deep(.tid-9TARM-logo) {
          @apply w-[34px] h-[18px] shrink-0 ml-0
          md:w-[68px] md:h-[36px];

          svg {
            @apply w-[inherit] h-[inherit];
          }
        }
      }
    }
  }

  &__return {
    @apply relative;

    &-toggle {
      @apply text-secondary-gray flex items-center justify-center gap-[4px] text-normal-XXS
      md:text-normal-M md:gap-12px;
    }

    &-modal {
      @apply absolute py-[10px] pl-12px gap-8px pr-4px bg-white flex text-primary-black text-normal-XXXS-compact rounded-tr-[8px] rounded-bl-[8px] rounded-br-[24px]
      md:text-normal-XS-semi md:mt-12px md:py-12px md:pl-20px md:pr-12px md:rounded-b-[24px] md:rounded-tr-[24px]
      shadow-[0_1px_3px_0_rgba(44,44,47,.1)];

      a {
        @apply text-secondary-blue;
      }

      &__icon {
        @apply shrink-0 text-secondary-blue;
      }
    }
  }
}
</style>

<style>
@import "assets/scss/nouislider.scss";
</style>
